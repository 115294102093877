import React from "react";
import cityNotifi from "../Images/city-notifi.png";
function SampBox() {
  return (
    <div className="row">
      <div className="col-md-1">
        <img src={cityNotifi} alt="notifi.png" width="55" height="66" />
      </div>
      <div className="col-md-11 mt-lg-4">
        Please make sure to monitor your SPAM and JUNK folders as well.
      </div>
    </div>
  );
}
export default SampBox;
