import { React, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import cityLogo from "../Images/logo.webp";

function ContentPopup() {
  const [show, setShow] = useState(true);

  const handleModal = (e) => {
    setShow(!show);
  };

  return (
    <div>
      <div>
        <Modal
          show={show}
          onHide={handleModal}
          id="modelContent"
          size="xl"
          centered
        >
          <Button id="pop-close1" onClick={handleModal}>
            &times;
          </Button>
          <Modal.Header id="header">
            <img id="popImg" src={cityLogo} alt="img-responsive" />
          </Modal.Header>
          <div className="model-body">
            <ul className="llist">
              <li key="1">
                Expect an email(s) within 5 business days from{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_EMAIL}>
                  {process.env.REACT_APP_DESC_EMAIL}
                </a>
                ,{" "}
                <a href={"mailto:" + process.env.REACT_APP_DESC_PREP}>
                  {process.env.REACT_APP_DESC_PREP}
                </a>{" "}
                or{" "}
                <a href={"mailto:" + process.env.REACT_APP_NO_REPLY}>
                  {process.env.REACT_APP_NO_REPLY}
                </a>{" "}
                <br />
                Please add these email addresses to your safe senders list in
                your email setting.
                <br />
                Once you receive your email(s) you may use the reward virtually
                or request plastic cards to be mailed.
              </li>
              <li key="2">
                You will need to click that link to finalize your order and
                select your preferred delivery method.
              </li>
              <li key="3">
                You will need to finalize your order by following the
                instructions in that email from <br />
                <a
                  className="fw-medium"
                  href={"mailto:" + process.env.REACT_APP_THANKPOPUP_EMAIL}
                >
                  {process.env.REACT_APP_THANKPOPUP_EMAIL}
                </a>
              </li>
              <br />
              <p>
                <b>
                  PLEASE NOTE: You only have 30 days to activate your card.
                  Check your inbox, junk and spam and contact our customer
                  service line if you haven't received the email within the
                  week.
                </b>
              </p>
            </ul>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ContentPopup;
