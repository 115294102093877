import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import useSortableData from "../../../hooks/useSortableData";
import useTable from "../../../hooks/useTable";
import AdminListService from "../../../services/admin-list.service";

import Footer from "../includes/Footer";
import Sidebar from "../includes/Sidebar";
import { useSelector } from "react-redux";

export default function ManageMails() {
  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken } = state;
  const [loading, setLoading] = useState(false);
  const [tableData, settableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const [limit, setLimit] = useState("20");

  const mailList = async (limit, page) => {
    setLoading(true);
    try {
      const responce = await AdminListService.listMails(limit, page);

      if (responce.status === 200) {
        const res = responce.data.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            CUSTOMER: value.CUSTOMER,
            JOB_NUMBER: value.JOB_NUMBER,
            CerAddOn: value.CerAddOn,
            count: value.count,
            groupNo: value.groupNo,
          });
        });

        settableData([...results]);

        setFilteredData([...results]);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      settableData([]);
      setFilteredData([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      mailList(limit, "1");
    }

    // eslint-disable-next-line
  }, []);
  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    // Filter the data based on the search query
    if (searchQuery !== "") {
      const newFilteredData = tableData.filter((item) => {
        // Customize this condition based on your data structure and search requirements
        return (
          item.CUSTOMER.toString().toLowerCase().includes(searchQuery) ||
          item.JOB_NUMBER.toString().toLowerCase().includes(searchQuery) ||
          item.CerAddOn.toString().toLowerCase().includes(searchQuery) ||
          item.count.toString().toLowerCase().includes(searchQuery)
        );
      });

      setFilteredData(newFilteredData);
    } else {
      setFilteredData(tableData);
    }
  };
  const Table = ({ data, rowsPerPage }) => {
    const [page, setPage] = useState(1);
    const { slice } = useTable(data, page, rowsPerPage);
    const { items, requestSort, sortConfig } = useSortableData(slice);
    const getClassNamesFor = (name) => {
      if (!sortConfig) {
        return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    return (
      <div className="table-responsive mt-3">
        <table className="table table-striped table-bordered W-100 sort-ths align-middle">
          <thead>
            <tr>
              <th
                scope="col"
                onClick={() => requestSort("JOB_NUMBER")}
                className={getClassNamesFor("JOB_NUMBER")}
              >
                Job Number
              </th>
              <th
                scope="col"
                onClick={() => requestSort("CUSTOMER")}
                className={getClassNamesFor("CUSTOMER")}
              >
                Company Name
              </th>
              <th
                scope="col"
                onClick={() => requestSort("CerAddOn")}
                className={getClassNamesFor("CerAddOn")}
              >
                Date Submitted
              </th>
              <th
                scope="col"
                onClick={() => requestSort("count")}
                className={getClassNamesFor("count")}
              >
                Total Users
              </th>

              <th
                scope="col"
                onClick={() => requestSort("groupNo")}
                className={getClassNamesFor("groupNo")}
              >
                Send Mail
              </th>
            </tr>
          </thead>
          <tbody>
            {items.length ? (
              items.map((el, index) => (
                <tr key={index}>
                  <td>{el.JOB_NUMBER}</td>
                  <td>{el.CUSTOMER}</td>
                  <td>{el.CerAddOn}</td>
                  <td>{el.count}</td>

                  <td>
                    <div className="d-flex align-items-center gap-3 fs-6">
                      <Link
                        className="btn btn-primary btn-sm"
                        to={"/sendmail/" + el.groupNo}
                      >
                        View all users
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-capitalize">
                  No record found
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {data.length && data.length > rowsPerPage ? (
          <Pagination
            activePage={page}
            itemsCountPerPage={parseInt(rowsPerPage)}
            totalItemsCount={data.length}
            onChange={(e) => {
              setPage(e);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText="First Page"
            lastPageText="Last Page"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <div className="card">
            <div className="card-header py-3">
              <h5 className="mb-0 font-22">Manage Mails</h5>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <select
                    className="form-select mt-2"
                    onChange={(e) => {
                      setLimit(e.target.value);
                    }}
                  >
                    <option value="20">20</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="1500">1500</option>
                    <option value={tableData.length}>All</option>
                  </select>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-3">
                  <input
                    type="text"
                    onChange={handleSearch}
                    placeholder="Type to search"
                    className="form-control mt-2"
                  />
                </div>
              </div>

              <Table data={filteredData} rowsPerPage={limit} />
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <div className={`loader ${loading ? "in" : ""}`}>
        <div className="spinner-border main-spin"></div>
      </div>
    </div>
  );
}
